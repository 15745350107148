import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-hot-toast';

import * as ApplicationModule from 'modules/application';
import * as ProcessModule from 'modules/process';
import * as SystemModule from 'modules/system';

import { http } from 'services';
import * as Table from 'components/Table';
import { Nav } from 'components/Modal';
import Type from 'components/Type';
import InfoBlock from 'components/InfoBlock';
import * as Details from 'components/Details';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import * as Icon from 'components/Icon';
import TextBlock from 'components/TextBlock';
import Status from 'components/Status';
import Modal from 'components/Modal';
import DetailByType from './DetailByType';
import classes from './ApplicationView.module.scss';
import { Link } from 'react-router-dom';
import Appeal from './components/Appeal';
import Notification from './components/Notification';
import { groupBy } from 'lodash';

interface IProps {
  id: number;
  close: () => void;
}

const View: React.FC<IProps> = ({ id, close }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const [appealVisible, setAppealVisible] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [isAppeal, setAppeal] = useState<boolean>(false);
  const [isNotification, setNotification] = useState<boolean>(false);


  const { item, isFetched } = ApplicationModule.Hooks.useSingle({
    id,
    onError: () => {
      close();
      toast.error(`${t('internal_server_error')}`);
    }
  });

  if (!item.id || !isFetched) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  }

  const STATUS = ApplicationModule.Constants.STATUS;
  const status = {
    [STATUS.IN_PROCESS]: 'orange' as const,
    [STATUS.CORRECTION]: 'orange' as const,
    [STATUS.WAITING_TERMINATION]: 'orange' as const,
    [STATUS.REVIEW]: 'orange' as const,
    [STATUS.IN_PAYMENT]: 'orange' as const,
    [STATUS.DENIED]: 'red' as const,
    [STATUS.CANCELLED]: 'red' as const,
    [STATUS.COMPLETED]: 'green' as const
  };

  const infoText = {
    [STATUS.CORRECTION]: `${t('application_send_to_correction_text_1')} ${t('application_send_to_correction_text_2')}`,
    [STATUS.IN_PAYMENT]: t('application_waiting_pay_text')
  };

  const download = file => {
    setDownloading(true);
    http.request
      .get(file?.download_uri, {
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file?.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .finally(() => {
        setDownloading(false);
        toast.success(String(t('download_complete')));
      });
  };

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={close} />
      <div className={classes.header}>
        <div className={classes.headerImage}>
          <img src={item.category.image} alt={get(item, `category.title[${language}]`)} />
        </div>
        <div className={classes.headerContent}>
          <div className={classes.tags}>
            <Type variant="blue" className="m-r-12">
              {get(item, `type[${language}]`)}
            </Type>
            {!!get(item, 'applicant.name') && <Type variant="dark-gray">{get(item, 'applicant.name')}</Type>}
          </div>
          <div className={classes.category}>{get(item, `category.title[${language}]`)}</div>
          <div className={classes.title}>{get(item, `document[${language}]`)}</div>
          <div className={classes.info}>
            <InfoBlock
              variant={status[item.status.key]}
              title={t('Status')}
              children={get(item, `status.title[${language}]`)}
              className="m-r-12"
            />
            <InfoBlock variant="white" title={t('application_number')} children={get(item, 'id')} className="m-r-12" />
            {get(item, 'register_id') && get(item, 'uuid') ? (
              <Link to={`/registry/${get(item, 'uuid')}`}>
                <InfoBlock
                  variant="white"
                  title={t('register_number')}
                  children={<>{get(item, 'register_id')}✔</>}
                  className="m-r-12"
                />
              </Link>
            ) : (
              ''
            )}
            {[STATUS.REVIEW].includes(item.status.key) && (
              <InfoBlock variant="white" title={t('review_period')} children={get(item, 'review_expiry')} />
            )}
            {[STATUS.CORRECTION].includes(item.status.key) && (
              <InfoBlock variant="white" title={t('correction_period')} children={get(item, 'correction_expiry')} />
            )}
            {/*<InfoBlock variant="white" title={t('date_of_issue')} children={item.register.date} className="m-r-12"/>*/}
            {/*<InfoBlock variant="white" title={t('Valid_until')} children={item.expires}/>*/}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.buttonList}>
          {item.status.can === 'edit' && (
            <>
              {
                item.status.key === STATUS.CORRECTION && (
                  <Button
                    prefixIcon={<Icon.System.Edit />}
                    onClick={() => history.push(`/application/${item.id}`)}
                  >
                    {t('Re-apply')}
                  </Button>
                )
              }
              {
                item.status.key === STATUS.IN_PAYMENT && (
                  <Button
                    prefixIcon={<Icon.System.Card />}
                    onClick={() => {
                      if (item.review_expired_automatically) {
                        setNotification(true);
                      } else {
                        history.push(`/application/${item.id}`)
                      }
                    }}
                  >
                    {t('Pay')}
                  </Button>
                )
              }
            </>
          )}
          <Button
            onClick={() => {
              if (
                (Array.isArray(item?.action_log) && item?.action_log?.length) ||
                (Array.isArray(item?.documents) && item?.documents?.length)
              ) {
                setAppealVisible(true);
              } else {
                return toast(String(t('no_process_monitoring')));
              }
            }}
          >
            {t('Process_monitoring')}
          </Button>
          {[STATUS.DENIED].includes(item.status.key) && (
            <Button onClick={() => setAppeal(true)}>{t('appilation_application')}</Button>
          )}
        </div>
        {[STATUS.CORRECTION, STATUS.IN_PAYMENT].includes(item.status.key) && (
          <TextBlock variant="BLUE" className="m-b-24">
            {infoText[item.status.key]}
          </TextBlock>
        )}
        <>
          {item.steps.map(step => {
            if (!step.groups.length || !(get(step, 'groups[0].fields') || []).length) {
              return null;
            }

            return (
              <Fragment key={`${step.title}-${step.position}`}>
                <div className={classes.listTitle}>{get(step, `title[${language}]`)}</div>
                <Details.List className="m-b-24">
                  {step.groups.map(group => {
                    if (!group.fields.length) {
                      return null;
                    }

                    const FIELD_TYPE = ProcessModule.Constants.FIELD_TYPE;
                    const STEP_TYPE = ProcessModule.Constants.STEP_TYPE;

                    const isGroupTitleVisible = ![STEP_TYPE.SPECIALIZATION, STEP_TYPE.REQUIREMENTS].includes(step.type);

                    const groupFields = orderBy(
                      group.fields.filter(item => ![FIELD_TYPE.TEXT_BLOCK].includes(item.type)),
                      ['position'],
                      ['asc']
                    );

                    if (group.isDuplicate) {
                      const valuesLength = (groupFields || []).reduce((prev, item) => {
                        const currentValue = (get(item, 'duplicableValues') || []).some(i =>
                          Number(get(i, 'position') > 1)
                        )
                          ? get(item, 'duplicableValues.length')
                          : 1;

                        return Number(prev) > Number(currentValue) ? Number(prev) : Number(currentValue);
                      }, 0);

                      if (!valuesLength) {
                        return null;
                      }

                      return (
                        <div key={`${group.title}-${group.position}`} className={classes.groupWrapper}>
                          <div className={classes.group}>
                            <div className={classes.groupTitle}>{get(group, `title[${language}]`)}</div>
                            <div className={classes.groupFields}>
                              {groupFields.map(field => get(field, `title[${language}]`)).join(', ')}
                            </div>

                            <div className={classes.groupList}>
                              {[...Array(valuesLength)].map((_, i) => (
                                <Details.List key={i} className={classes.groupListItem}>
                                  {groupFields.map(field => {
                                    if (field.type === "SPECIALIZATIONS") {
                                      let items = Object.entries(groupBy(field.duplicableValues, "position"))

                                      field.duplicableValues = []

                                      for (const [position, groupByField] of items) {
                                        let value = groupByField.reduce((acc, item) => {
                                          let value = item.value

                                          if (item.hasOption) {
                                            value = item.option[i18n.language]
                                          }

                                          if (acc) {
                                            return acc + ", " + value
                                          }

                                          return value
                                        }, '')

                                        field.duplicableValues.push({
                                          hasOption: false,
                                          option: {
                                            uz: '',
                                            en: "",
                                            oz: "",
                                            ru: ""
                                          },
                                          position: Number(position),
                                          value
                                        })
                                      }
                                    }

                                    const title = get(field, `title[${language}]`);
                                    const item = (get(field, `duplicableValues`) || []).find(
                                      item => get(item, 'position') === i + 1
                                    );
                                    let value = !!get(item, 'hasOption')
                                      ? get(item, `option[${language}]`)
                                      : get(item, 'value');

                                    if (get(field, `key`) === 'SELECTED_SPECIALIZATIONS_BY_LOCATION') {
                                      value = (get(field, `duplicableValues`) || [])
                                        .map(i => {
                                          return !!get(i, 'hasOption')
                                            ? get(i, `option[${language}]`)
                                            : get(i, 'value');
                                        })
                                        .join(', ');
                                    }

                                    return (
                                      <DetailByType
                                        {...{ title }}
                                        value={value || ''}
                                        type={field.type}
                                        applicationId={id}
                                        stepType={step.type}
                                      />
                                    );
                                  })}
                                </Details.List>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <Group
                        title={get(group, `title[${language}]`)}
                        position={group.position}
                        fields={groupFields}
                        withWrapper={isGroupTitleVisible}
                      >
                        {group.fields.map(field => {
                          const title = get(field, `title[${language}]`);
                          const value = get(field, `option[${language}]`) ? get(field, `option[${language}]`) : field.value;

                          return (
                            <DetailByType
                              {...{ title }}
                              field={field}
                              value={value || ''}
                              type={field.type}
                              applicationId={id}
                              stepType={step.type}
                            />
                          );
                        })}
                      </Group>
                    );
                  })}
                </Details.List>
              </Fragment>
            );
          })}
        </>
        <Modal variant='white' isOpen={isNotification} width={800} close={() => setNotification(false)}>
          <Notification text={`${item.applicant.name} (${item.applicant.tin})`} applicationId={item.id} onClose={() => setNotification(false)} />
        </Modal>
        <Modal isOpen={isAppeal} width={800} close={() => setAppeal(false)}>
          <Appeal applicationId={item.id} onClose={() => setAppeal(false)} />
        </Modal>
        <Modal size="large" isOpen={appealVisible} close={() => setAppealVisible(false)}>
          <Nav showClose onClose={() => setAppealVisible(false)} />
          <div className={classes.modal}>
            {Array.isArray(item?.documents) && item?.documents?.length ? (
              <>
                <div className={classes.listTitle}>{t('Documents')}</div>
                <Table.Table>
                  <Table.Header>
                    <Table.HeaderCell>{t('Organizations')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('File')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('application_date_begin')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('application_date_end')}</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {item?.documents?.map((document, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{get(document, `organization[${language}]`)}</Table.Cell>
                        <Table.Cell>
                          {get(document, `file`) && (
                            <Button
                              isLoading={downloading}
                              onClick={() => download(get(document, `file`))}
                              variant="stroke"
                              prefixIcon={<Icon.System.Download />}
                            >
                              {get(document, `file.name`)}
                            </Button>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {
                            {
                              DENIED: <Status variant="danger">{t('status_denied')}</Status>,
                              APPROVED: <Status variant="success">{t('status_completed')}</Status>,
                              ON_CHECK: <Status variant="warning">{t('status_review')}</Status>
                            }[get(document, `status`)]
                          }
                        </Table.Cell>
                        <Table.Cell>{get(document, `date`)}</Table.Cell>
                        <Table.Cell>{get(document, `deadline`)}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table.Table>
              </>
            ) : null}
            {Array.isArray(item?.action_log) && item?.action_log?.length ? (
              <>
                <div className={classes.listTitle}>{t('action_log')}</div>
                <Table.Table>
                  <Table.Header>
                    <Table.HeaderCell>{t('user')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('old_status')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('new_status')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Date')}</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {item?.action_log?.map((log, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{get(log, `user`)}</Table.Cell>
                        <Table.Cell>
                          <Status variant={['NEW'].includes(get(log, `old_review_status.key`)) ? 'success' : 'warning'}>
                            {get(log, `old_review_status.title[${language}]`)}
                          </Status>
                        </Table.Cell>
                        <Table.Cell>
                          <Status
                            variant={['APPROVED'].includes(get(log, `review_status.key`)) ? 'success' : 'warning'}
                          >
                            {get(log, `review_status.title[${language}]`)}
                          </Status>
                        </Table.Cell>
                        <Table.Cell>{get(log, `date`)}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table.Table>
              </>
            ) : null}
          </div>
        </Modal>
      </div>
    </div>
  );
};

const Group = ({ title, position, fields, children, withWrapper }) => {
  const { i18n } = useTranslation();

  if (!withWrapper) {
    return <Details.List>{children}</Details.List>;
  }

  return (
    <div key={`${title}-${position}`} className={classes.groupWrapper}>
      <div className={classes.group}>
        <div className={classes.groupTitle}>{title}</div>
        <div className={classes.groupFields}>
          {fields.map(field => get(field, `title[${i18n.language}]`)).join(', ')}
        </div>
        <div className={classes.groupList}>
          <Details.List className={classes.groupListItem}>{children}</Details.List>
        </div>
      </div>
    </div>
  );
};

export default View;
