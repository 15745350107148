import React from 'react';
import get from 'lodash/get';
import HtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';

import * as ProcessModule from 'modules/process';
import * as DocumentModule from 'modules/document';
import * as SystemModule from 'modules/system';

import * as Details from 'components/Details';
import Spinner from 'components/Spinner';

import Requirements from './Requirements';

import classes from './DocumentInfo.module.scss';

interface IProps {
  documentId: number;
  item: DocumentModule.Types.IEntity.Info;
  isFetched: boolean;
  isLoaded: boolean;
}

const DocumentInfo: React.FC<IProps> = ({ documentId, item, isFetched, isLoaded }) => {
  const specialization = ProcessModule.Hooks.useSpecialization({ documentId });
  const { language } = SystemModule.Hooks.useLanguage();
  const { t, i18n } = useTranslation();

  if (!isFetched && !isLoaded) {
    return <Spinner />;
  }

  const workingDays = get(item, 'review.timeBegin')
    ? t(get(item, 'review.isWeekDays') ? 'up_to_working_days_min_max' : 'up_to_working_days_min_max_is_week', {
        min: get(item, 'review.timeBegin'),
        max: get(item, 'review.time')
      })
    : get(item, 'review.time')
    ? t(get(item, 'review.isWeekDays') ? 'up_to_working_days' : 'up_to_days', { value: get(item, 'review.time') })
    : t('document_online_fast');

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('Detailed_information')}</div>
      <Details.List className={classes.description}>
        <Details.Item title={t('Licensing authority')}>{get(item, `organization[${language}]`)}</Details.Item>
        {!!get(item, `applicantType[${language}]`) && (
          <Details.Item title={t('Applicant')}>{get(item, `applicantType[${language}]`)}</Details.Item>
        )}
        <Details.Item title={t('Term_public_service')}>{workingDays}</Details.Item>
        {!!get(item, 'document.number') && get(item, 'document.date') && (
          <Details.Item className={classes.link} title={t('document_number_and_date')}>
            {get(item, 'document.number')} {get(item, 'document.date')}
          </Details.Item>
        )}
        {!get(item, `state_duty_name[${i18n.language}]`) ? (
          get(item, 'dutyBegin') && get(item, 'duty') && parseFloat(get(item, 'dutyBegin')) > 0 ? (
            <Details.Item
              className={classes.link}
              title={!['LICENSE'].includes(item.type.key) ? t('given_service_duty') : t('document_service_duty')}
            >
              {`${t('format_amount_from', { value: get(item, 'dutyBegin') })} - ${t('format_amount_to', {
                value: get(item, 'duty')
              })}`}
            </Details.Item>
          ) : (
            <Details.Item
              className={classes.link}
              title={!['LICENSE'].includes(item.type.key) ? t('given_service_duty') : t('document_service_duty')}
            >
              {get(item, 'duty') ? t('format_amount', { value: get(item, 'duty') }) : t('free')}
            </Details.Item>
          )
        ) : (
          <Details.Item
            className={classes.link}
            title={!['LICENSE'].includes(item.type.key) ? t('given_service_duty') : t('document_service_duty')}
          >
            {get(item, `state_duty_name[${i18n.language}]`)}
          </Details.Item>
        )}

        <Details.Item className={classes.link} title={t('document_expire_date')}>
          {!get(item, `expire_date_name[${i18n.language}]`)
            ? get(item, 'expire_date_begin')
              ? t('license_duration_min_max', { min: get(item, 'expire_date_begin'), max: get(item, 'expire_date') })
              : Number(get(item, 'expire_date'))
              ? get(item, 'expiry')
                ? `${get(item, 'expire_date')} ${t(get(item, 'expiry'))}`
                : t('license_duration', { count: get(item, 'expire_date') as any })
              : t('limitless')
            : get(item, `expire_date_name[${i18n.language}]`)}
        </Details.Item>

        <Details.Item className={classes.link} title={t('document_usage_fee')}>
          {Number(get(item, 'usageFee')) ? t('document_usage_fee_exists') : t('document_usage_fee_not_exists')}
        </Details.Item>
        {!!item.link && (
          <Details.Item className={classes.link} title={t('Reference_approved_procedure_services')}>
            <a href={item.link} target="_blank">
              {item.link}
            </a>
          </Details.Item>
        )}
        {!!item.handbookLink && (
          <Details.Item className={classes.link} title={t('handbook_about_usage_this_service')}>
            <a href={item.handbookLink} target="_blank">
              {t('watch_handbook')}
            </a>
          </Details.Item>
        )}
      </Details.List>
      {!!item.extra.length && (
        <div className={classes.extraInformation}>
          <div className={classes.extraInformationTitle}>{t('Extra_information')}</div>
          <div className={classes.list}>
            {item.extra.map((item, i) => (
              <div key={i} className={classes.listItem}>
                <div className={classes.listItemNumber}>{i + 1}</div>
                <div className={classes.listItemContent}>{HtmlParser(get(item, `title[${language}]`))}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!!specialization.items.length && (
        <>
          <div className={classes.specializations}>
            <div className={classes.specializationsTitle}>{t('Specializations')}</div>
            <div className={classes.list}>
              {orderBy(specialization.items, ['position'], ['asc']).map((item, i) => {
                const title = get(item, `[name][${language}]`);
                const description = get(item, `[description][${language}]`);
                const annualFee = get(item, `annualFee`);

                return (
                  <div key={item.id} className={classes.listItem}>
                    <div className={classes.listItemNumber}>{i + 1}</div>
                    <div className={classes.listItemContent}>
                      <div className={classes.listItemContentTitle}>
                        <span>{HtmlParser(title)}</span>
                        <div>
                          {item?.expiryDate && <span>{t(item?.expiry, { date: item?.expiryDate })} | </span>}
                          {!!annualFee && <span>{t('annual_fee_symbol', { fee: annualFee })}</span>}
                        </div>
                      </div>
                      {!!description && <p>{HtmlParser(description)}</p>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Requirements specializationIds={specialization.items.map(item => `${item.id}`)} {...{ documentId }} />
        </>
      )}
    </div>
  );
};

export default DocumentInfo;
