import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import config from 'config';

import * as FeedbackModule from 'modules/feedback';

import * as Icon from 'components/Icon';
import Modal from 'components/Modal';

import classes from './Footer.module.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [complaintVisible, setComplaintVisible] = useState(false);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.menu}>
            <div className={classes.menuTitle}>{t('Site_navigation')}</div>
            <div className={classes.menuList}>
              <Link to="/" className={classes.menuItem}>
                {t('General')}
              </Link>
              <Link to="/registry" className={classes.menuItem}>
                {t('Registry')}
              </Link>
              <Link to="/documents" className={classes.menuItem}>
                {t('check_documents')}
              </Link>
              <Link to="/news" className={classes.menuItem}>
                {t('News')}
              </Link>
              <Link to="/statistics" className={classes.menuItem}>
                {t('Statistics')}
              </Link>
              <Link to="/help" className={classes.menuItem}>
                {t('Help')}
              </Link>
              <Link to="/organizations" className={classes.menuItem}>
                {t('licensing_organizations')}
              </Link>
              <div onClick={() => setComplaintVisible(true)} className={classes.menuItem}>
                {t('leave_suggestions_or_comments')}
              </div>
              <Link to="/cabinet" className={classes.menuItem}>
                {t('Login_your_account')}
              </Link>
            </div>
          </div>
          <div className={classes.help}>
            <div className={classes.helpTitle}>{t('Need_help?')}</div>
            <div className={classes.helpList}>
              <div className={classes.helpItem}>
                <div className={classes.helpItemIcon}>
                  <Icon.System.Phone />
                </div>
                <div className={classes.helpItemValue}>
                  <a href="tel:+998712079111">+998 71 207 91 11</a>
                </div>
              </div>
              <div className={classes.helpItem}>
                <div className={classes.helpItemIcon}>
                  <Icon.System.Phone />
                </div>
                <div className={classes.helpItemValue}>
                  <a href="tel:1148">1148</a>
                </div>
              </div>
              <div className={classes.helpItem}>
                <div className={classes.helpItemIcon}>
                  <Icon.System.Mail />
                </div>
                <div className={classes.helpItemValue}>
                  <a href="mailto:info@adliya.uz">info@adliya.uz</a>
                </div>
              </div>
              {/* https://b-advice.uz/  */}
              {/* <div className={classes.helpItem}>
                <div className={classes.helpItemIcon}>
                  <Icon.System.Company />
                </div>
                <div className={classes.helpItemValue}>
                  <a href="https://b-advice.uz/" target="_blank">
                    {t('b-advice')}
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className={classes.store}>
            <div className={classes.storeTitle}>{t('Download_app')}</div>
            <div className={classes.storeList}>
              <a href={config.apps.android} className={classes.storeItem} target="_blank">
                <img src={require('assets/images/store/playstore.svg')} alt="playstore" />
              </a>
              <a href={config.apps.ios} className={classes.storeItem} target="_blank">
                <img src={require('assets/images/store/appstore.svg')} alt="appstore" />
              </a>
            </div>
          </div>
        </div>
        <div className={classes.organizationList}>
          <div className={classes.organizationItem}>
            <img src={require('assets/images/organizations/undp.svg')} alt="undp" />
          </div>
          <div className={classes.organizationItem}>
            <img src={require('assets/images/organizations/european-union.svg')} alt="european-union" />
          </div>
          <div className={classes.organizationItem}>
            <Icon.Logo.Horizontal width={70} />
          </div>
        </div>
        <div className={classes.copyright}>
          {t('All_right_reserved_1')} {t('All_right_reserved_2')}
        </div>
      </div>

      <Modal variant="white" size="small" isOpen={complaintVisible} close={() => setComplaintVisible(false)}>
        <FeedbackModule.Components.Complaint close={() => setComplaintVisible(false)} />
      </Modal>
    </>
  );
};

export default Footer;
