export enum FIELD_TYPE {
  INPUT = 'INPUT',
  DATE = 'DATE',
  TIME = 'TIME',
  DATE_TIME = 'DATE_TIME',
  TEXT_BLOCK = 'TEXT_BLOCK',
  TEXT_AREA = 'TEXT_AREA',
  TEXT_EDITOR = 'TEXT_EDITOR',
  FILE = 'FILE',
  OPEN_SOURCE_FILE = 'OPEN_SOURCE_FILE',
  DROP_DOWN = 'DROP_DOWN',
  RADIO_BUTTON = 'RADIO_BUTTON',
  CHECK_BOX = 'CHECK_BOX',
  SWITCH = 'SWITCH',
  MAP = 'MAP',
  GROUP = 'GROUP',
  SPECIALIZATIONS = 'SPECIALIZATIONS',
  SUB_GROUP = 'SUB_GROUP'
}

export enum FIELD_POSITION_TYPE {
  FULL = 'FULL',
  HALF = 'HALF'
}

export enum FIELD_KEYBOARD {
  TEXT = 'TEXT',
  CAP_SENTENCES = 'CAP_SENTENCES',
  CAP_WORDS = 'CAP_WORDS',
  CAP_CHARACTERS = 'CAP_CHARACTERS',
  NUMBER = 'NUMBER',
  EMAIL = 'EMAIL',
  WEB_URL = 'WEB_URL'
}

export enum FIELD_TEXT_BLOCK_TYPE {
  TEXT = 'TEXT',
  HTML = 'HTML'
}

export enum FIELD_TEXT_BLOCK_COLOR {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  SIMPLE = 'SIMPLE'
}

export enum STEP_TYPE {
  REVIEW = "REVIEW",
  FORM = 'FORM',
  SPECIALIZATION = 'SPECIALIZATION',
  REQUIREMENTS = 'REQUIREMENTS',
  PAYMENT = 'PAYMENT',
  STATUS = 'STATUS'
}

export enum APPLICANT_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
  FOREIGN_INDIVIDUAL = 'FOREIGN_INDIVIDUAL',
  FOREIGN_LEGAL_ENTITY = 'FOREIGN_LEGAL_ENTITY'
}

export enum TYPE {
  REGISTRATION = 'REGISTRATION',
  CHANGE = 'CHANGE',
  TERMINATION = 'TERMINATION',
  SUSPEND = 'SUSPEND',
  MODIFICATION = 'MODIFICATION',
  EXTENSION = 'EXTENSION',
  SUSPEND_TO_ACTIVE = 'SUSPEND_TO_ACTIVE'
}

export enum STATUS {
  COMPLETED = 'COMPLETED',
  IN_PROCESS = 'IN_PROCESS'
}

export enum BRANCH_APPLICATION_TYPE {
  REGISTRATION = 'REGISTRATION',
  MODIFICATION = 'MODIFICATION'
}

export const STORE_RESET = '@@STORE/RESET';

export enum START {
  REQUEST = '@@PROCESS/START/REQUEST',
  SUCCESS = '@@PROCESS/START/SUCCESS',
  FAILURE = '@@PROCESS/START/FAILURE'
}

export enum START_BY_REGISTER {
  REQUEST = '@@PROCESS/START_BY_REGISTER/REQUEST',
  SUCCESS = '@@PROCESS/START_BY_REGISTER/SUCCESS',
  FAILURE = '@@PROCESS/START_BY_REGISTER/FAILURE'
}

export enum START_BY_BRANCH {
  REQUEST = '@@PROCESS/START_BY_BRANCH/REQUEST',
  SUCCESS = '@@PROCESS/START_BY_BRANCH/SUCCESS',
  FAILURE = '@@PROCESS/START_BY_BRANCH/FAILURE'
}

export enum START_BY_BRANCH_SINGLE {
  REQUEST = '@@PROCESS/START_BY_BRANCH_SINGLE/REQUEST',
  SUCCESS = '@@PROCESS/START_BY_BRANCH_SINGLE/SUCCESS',
  FAILURE = '@@PROCESS/START_BY_BRANCH_SINGLE/FAILURE'
}
export enum UPDATE {
  REQUEST = '@@PROCESS/UPDATE/REQUEST',
  SUCCESS = '@@PROCESS/UPDATE/SUCCESS',
  FAILURE = '@@PROCESS/UPDATE/FAILURE'
}

export enum BACK {
  REQUEST = '@@PROCESS/BACK/REQUEST',
  SUCCESS = '@@PROCESS/BACK/SUCCESS',
  FAILURE = '@@PROCESS/BACK/FAILURE'
}

export enum RESUME {
  REQUEST = '@@PROCESS/RESUME/REQUEST',
  SUCCESS = '@@PROCESS/RESUME/SUCCESS',
  FAILURE = '@@PROCESS/RESUME/FAILURE'
}

export enum CANCEL {
  REQUEST = '@@PROCESS/CANCEL/REQUEST',
  SUCCESS = '@@PROCESS/CANCEL/SUCCESS',
  FAILURE = '@@PROCESS/CANCEL/FAILURE'
}

export enum SPECIALIZATION {
  REQUEST = '@@PROCESS/SPECIALIZATION/REQUEST',
  SUCCESS = '@@PROCESS/SPECIALIZATION/SUCCESS',
  FAILURE = '@@PROCESS/SPECIALIZATION/FAILURE'
}

export enum REQUIREMENTS {
  REQUEST = '@@PROCESS/REQUIREMENTS/REQUEST',
  SUCCESS = '@@PROCESS/REQUIREMENTS/SUCCESS',
  FAILURE = '@@PROCESS/REQUIREMENTS/FAILURE'
}

export enum ACTION_EVENT {
  REQUEST = '@@PROCESS/ACTION_EVENT/REQUEST',
  SUCCESS = '@@PROCESS/ACTION_EVENT/SUCCESS',
  FAILURE = '@@PROCESS/ACTION_EVENT/FAILURE'
}

export enum SPECIALIZATION_EXPIRY {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
