import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import CheckboxArray from 'components/Fields/Checkbox/CheckboxArray';

import classes from './FieldSpecializations.module.scss';

interface IProps {
  name: string;
  label?: string;
  required?: boolean;

  items: any[];
}

const Specializations: React.FC<IProps> = ({ name, label, required, items }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes.wrapper}>
      {!!label && <div className={classes.title} title={label}>{!!required && <span>*</span>}{label}</div>}
      <div className={classes.list}>
        {items.map((item, i) => {
          const number = i + 1;
          const description = get(item, `description[${i18n.language}]`);
          return (
            <label key={number} className={classes.item}>
              <div className={classes.itemCheckbox}>
                <CheckboxArray {...{ name }} value={String(item.id)} />
              </div>
              <div className={classes.itemContent}>
                <div className={classes.itemTitle}>
                  <span>{get(item, `name[${i18n.language}]`)}</span>
                  {item?.expiryDate && <span>{t(item?.expiry, { date: item?.expiryDate })}</span>}
                </div>
                {!!description && (
                  <div className={classes.itemDescription}>{description}</div>
                )}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default Specializations;
